/*
Marketplace contract:  0x26556d05Aa70c5D68eEE920ca6ef7FF5491A1DF0
Marketplace proxy contract:  0xb994f0ec4E9D121Ef50911767900d9Ca15d75D3a
main token contract:  0xa71BCf23b5619e0acB8c130AC5db2191f036CAF5
main token proxy contract:  0x322E2EcA072ab5AFcd624D93B562F9AE5669A634
*/
import payementTokenAbi from "../data/Abis/payementToken.json";
import swapTokenAbi from "../data/Abis/swapContractAbi.json";
import platformTokenAbi from "../data/Abis/platformTokenAbi.json";
import midasAbi from "../data/Abis/midasContractAbi.json";
import nftAbi from "../data/Abis/nftAbi.json";

const firebaseSettings = {
  apiKey: "AIzaSyD-L5sNph9cTYKiGGxKa-Q9wm_UOVV1Mwc",
  authDomain: "rpw-5d4c5.firebaseapp.com",
  projectId: "rpw-5d4c5",
  storageBucket: "rpw-5d4c5.appspot.com",
  messagingSenderId: "331525507563",
  appId: "1:331525507563:web:7d97b14bc22ea43d3bf1ae",
};
export const AppParams_recette = {
  MARKET_ADDRESS: "0x3a8341F539ee503f01F85e58e857d297693C6176",
  AppChainId: "0xa869",
  MAIN_TOKEN_CONTRACT_ADDRESS: "0x634ddF22E56afEb6118D83aA242cDacf1a1f8367",
  PAYEMENT_TOKEN_NAME: "USDT",
  ASSET_TOKEN_CONTRACT_ADDRESS: "0x5F5a58850f2b92FC1D14603245ab63E301D46704",
  FEES_REDUCER_TOKEN_CONTRACT_ADDRESS:
    "0x023be12ee8B09C3c15D15c89DE66F24890803ca5",
  ANT_ERC20_CONTRACT_ADDRESS: "0xd66b8E3685fBc8f98EdEC0810dD27Faeb51bb0F2",
  STAKING_CONTRACT_ADDRESS: "0xf58AaCC929ECe27410400a882748DE97d8a1968e",
  WALLET_HOLDERS_CONTRACT_ADDRESS: "0x37f332915a14E5f0796B9c3aaa19cDf140f41262",
  RPC_URL: "https://api.avax-test.network/ext/bc/C/rpc",
  BLOCK_EXPLORER_URL: "https://goerli.etherscan.io/",
  NFT_MARKET_GRAPH:
    "https://api.thegraph.com/subgraphs/name/o2b-nft-marketplace/o2b-marketplace-goerli",
  STAKING_GRAPH:
    "https://api.thegraph.com/subgraphs/name/ant-nft-subgraph/ant-staking-dev",
  TOKEN_GRAPH:
    "https://api.thegraph.com/subgraphs/name/o2b-nft-marketplace/o2b-nft-goerli",
  IPFS_GATWAY: "https://ant-finance-dev.mypinata.cloud/ipfs/",
  BACKEND_URL: "http://localhost:8443",
  PAYEMENT_TOKEN_ADDRESS: "0xcE3D8656Ef5050481eC5D0404d6c8D1130fe3789",
  PAYEMENT_TOKEN_DECIMALS: 18,
  PAYEMENT_TOKEN_ABI: payementTokenAbi,
  SWAP_CONTRACT_ADDRESS: "0x6e1fF984b81b12f3dd80BC1773aeB29413062d19",
  SWAP_CONTRACT_ABI: swapTokenAbi,
  PLATFORM_TOKEN_NAME: "GHOLD",
  PLATFORM_TOKEN_SYMBOL: "GHOLD",
  PLATFORM_TOKEN_ABI: platformTokenAbi,
  PLATFORM_TOKEN_ADDRESS: "0x0a7863696178d545c6344d9C3cC40DA3024bFDE4",
  PLATFORM_TOKEN_DECIMALS: 18,
  PLATFORM_TOKEN_LOGO:
    "https://res.cloudinary.com/decpwvhfk/image/upload/v1676295303/Rpw%20v2/base_icon_white_background_pggsd3.png",
  PLATFORM_FEES_RATE: 2,
  PLATFORM_FEES_NUMBER_OF_DECIMALS: 0,
  MIDAS_CONTRACT_ADDRESS: "0xe23bB3701a15471fde2CBdfbBE2431DdEcEF8D80",
  MIDAS_CONTRACT_ABI: midasAbi,
  NFT_CONTRACT_ADDRESS: "0x615db41BFBe54Cb63Ecfae7F07a3c3d7553FBa22",
  NFT_CONTRACT_ABI: nftAbi,
  FIREBASE_SETTINGS: firebaseSettings,
  GOLD_PRICE: 64,
};
